import { Component } from "react";

class MainComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			insightData: null,
			dataModal: null,
		};
		this.cardStyle = {
			width: 100 + "%",
		};
		this.imgStyle = {
			maxHeight: 42 + `rem`,
		};
		this.cardSettings = {
			cardTitle: `Artefaktas Mobile test`,
			cardStatusText: `Test status`,
			insightStatusTex: `Pagespeed status`,
			buttonText: `Test it !`,
		};
		this.textAreaStyle = {
			height: `auto`,
		};
		this.getInputValue = this.getInputValue.bind(this);
		this.requestFunction = this.requestFunction.bind(this);
		this.requestScreenshot = true;
		this.reModal = new window.bootstrap.Modal(
			document.getElementById("responseModal"),
			{}
		);
		this.insightModal = new window.bootstrap.Modal(
			document.getElementById("insightmodal"),
			{}
		);
	}

	createModal() {
		this.reModal.show();
	}

	closeModal() {
		this.reModal.hide();
	}

	createInsightmodal() {
		this.insightModal.show();
	}

	closeInsightModal() {
		this.insightModal.hide();
	}

	requestFunction(params) {
		const requestUrl = `https://mobiletest.artefaktas.workers.dev/api`;
		const insightsUrl = `https://pegespeed.artefaktas.workers.dev/api`;

		document.querySelector("#controlButton").classList.add("disabled");
		this.createModal();
		async function getData() {
			const res = await fetch(requestUrl, {
				method: "POST",
				body: JSON.stringify(params),
			});
			const jsonData = await res.json();
			return jsonData;
		}

		async function getInsights() {
			const res = await fetch(insightsUrl, {
				method: "POST",
				body: JSON.stringify(params),
			});
			const jsonInsight = await res.json();
			const lightData = jsonInsight["lighthouseResult"];
			return lightData;
		}

		getData().then((res) => {
			document.querySelector("#controlButton").classList.remove("disabled");
			this.setState({ data: res }, () => {
				this.closeModal();
				this.createInsightmodal();
				getInsights().then((dat) => {
					this.setState({ insightData: dat }, () => {
						this.closeInsightModal();
						console.log(dat);
					});
				});
			});
		});
	}

	getInputValue() {
		let fetchLink = String(document.querySelectorAll(".urlInput")[0].value);
		let mainObject = {
			url: fetchLink,
			requestScreenshot: this.requestScreenshot,
		};
		this.requestFunction(mainObject);
	}

	lightGen() {
		if (
			this.state.insightData !== null &&
			this.state.insightData !== undefined &&
			this.state.insightData.audits !== undefined
		) {
			const lighthouseMetrics = `First Contentful Paint: ${this.state.insightData.audits["first-contentful-paint"].displayValue}\n\nSpeed Index: ${this.state.insightData.audits["speed-index"].displayValue}\n\nTime To Interactive: ${this.state.insightData.audits["interactive"].displayValue}\n\nFirst Meaningful Paint: ${this.state.insightData.audits["first-meaningful-paint"].displayValue}`;
			return lighthouseMetrics;
		} else {
			return "No tests were performed\n\nIssues might be expected (Experimental function)";
		}
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="row py-4">
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mx-auto">
						<div className="card" style={this.cardStyle}>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
									<img
										src={
											this.state.data !== null && this.state.data !== ""
												? this.state.data.screenshot !== undefined
													? "data:" +
													  this.state.data.screenshot.mimeType +
													  ";base64," +
													  this.state.data.screenshot.data
													: "https://images.unsplash.com/photo-1561154464-82e9adf32764?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
												: "https://images.unsplash.com/photo-1561154464-82e9adf32764?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
										}
										className="card-img-top"
										alt="screenshot"
										style={this.imgStyle}
									/>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
									<div className="card-body">
										<h5 className="card-title my-4">
											{this.cardSettings.cardTitle}
										</h5>
										<input
											className="form-control form-control-lg urlInput"
											placeholder="Type in your url"
										></input>
										<button
											onClick={this.getInputValue}
											className="btn btn-md btn-primary form-control form-control-lg my-4"
											id="controlButton"
										>
											{this.cardSettings.buttonText}
										</button>
										<h5 className="card-title my-4">
											{this.cardSettings.cardStatusText}
										</h5>
										<textarea
											rows="8"
											style={this.textAreaStyle}
											className="form-control form-control-lg statusField my-2"
											disabled
											value={
												this.state.data !== null
													? this.state.data.mobileFriendliness !== undefined
														? `Mobile friendly: ${this.state.data.mobileFriendliness}\n\nTest status ${this.state.data.testStatus.status}`
														: "Error"
													: "No tests were performed"
											}
										></textarea>
										<h5 className="card-title my-4">
											{this.cardSettings.insightStatusTex}
										</h5>
										<textarea
											rows="8"
											style={this.textAreaStyle}
											className="form-control form-control-lg statusField my-2"
											disabled
											value={this.lightGen()}
										></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MainComponent;
